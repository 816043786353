import React from 'react';

import ShowcasePage from '../../../components/ShowcasePage';

const Showcase = () => (
  <ShowcasePage
    totalItems={22}
    s3Path={`portfolio/family/1/`}
  />
)

export default Showcase;
